interface ToastOptions {
  message: string;
  type: "success" | "error" | "warning" | "info";
}

/**
 * toast dom元素
 */
let toastDom: HTMLDivElement | null = null;
/**
 * 创建的第几个toast
 */
let toastInx = 0;
/**
 * 创建一个提示
 * @param opt
 */
export function creToast(opt: ToastOptions): HTMLDivElement {
  if (!toastDom) {
    toastDom = document.createElement("div");
    toastDom.className = `toast toast-top toast-center`; // 添加类
  }
  const { message, type } = opt;
  // 创建一个新的 div 元素
  const index = `toast-sub-${toastInx++}`;
  // 将传入的字符串设置为该 div 的 innerHTML
  toastDom.insertAdjacentHTML(
    "beforeend",
    `<div id="${index}" class="alert ${
      type ? "alert-" + type : "alert-success"
    }">
          <span>${message}</span>
        </div>`
  );
  // 将新创建的元素插入到 body 中
  document.body.appendChild(toastDom);
  // 设置一个定时器，在两秒后删除该元素
  const st = setTimeout(() => {
    toastDom!.removeChild(toastDom!.querySelector(`#${index}`)!);
    clearTimeout(st);
  }, 2000);
  return toastDom;
}

/**
 * 根据id获取元素
 * @param id
 * @returns
 */
export function domById<T>(id: string, dom?: any): T | null {
  if (dom) {
    return dom.querySelector(id);
  }
  //   @ts-ignore
  return document.getElementById(id);
}

interface BtnOptions {
  callback: () => void;
  text?: string;
}

interface ModalOptions {
  id: string;
  title?: string;
  content?: string;

  /**
   * html内容
   */
  contentHtml?: string;
  confirm?: BtnOptions;
  cancel?: BtnOptions;
  // cancel?: () => void;
  // cancelTxt?: string;
  // confirmTxt?: string;
  // innerHTML?: string;
}
/**
 * 创建一个对话弹窗
 * @param opt
 * @returns
 */
export function creModal(opt: ModalOptions): HTMLDialogElement {
  const element = document.createElement("dialog");
  element.className = `modal`; // 添加类
  element.id = opt.id;
  const { confirm: cof, cancel: ccl } = opt;
  element.innerHTML = `<div class="modal-box">
            <form method="dialog">
            <button class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
            </form>
            ${
              opt.title ? `<h3 class="text-lg font-bold">${opt.title}</h3>` : ""
            }
            ${opt.content ? `<p class="py-4">${opt.content}</p>` : ""}
            <div class="mb-rerow">
                ${
                  cof
                    ? `<button class="btn btn-sm btn-primary">${cof.text}</button>`
                    : ""
                }
            </div>
        </div>`;

  // 将新创建的元素插入到 body 中
  document.body.appendChild(element);
  element.showModal();
  // element.cloneNode()
  if (cof) {
    const confirmButton = element.querySelector(`.btn-primary`);
    if (confirmButton) {
      confirmButton.addEventListener("click", cof.callback);
    }
  }
  if (ccl) {
    const closeBtn = element.querySelector(`.btn-ghost`);
    if (closeBtn) {
      closeBtn.addEventListener("click", ccl.callback);
    }
  }

  return element;
}
