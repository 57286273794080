import { gld } from "../../store/global.data";
import "./pageHeader.scss";
import { useNavigate } from "@solidjs/router";

export function PageHeader() {
  const router = useNavigate();

  function goBack() {
    router(-1);
  }
  return (
    <>
      <div class="p_h" style={gld.sbh ? `padding-top:${gld.sbh}px` : ""}>
        <button class="btn btn-sm" onClick={goBack}>{`<`}</button>
      </div>
    </>
  );
}
