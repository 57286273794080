/** @format */
import { PageHeader } from "../../components/pageHeader/pageHeader";
import {
  recordHistory,
  setRecordHistory,
  setUserInfo,
  userInfo,
} from "../../store/global.data";
import "./property.scss";
import { createStore, produce } from "solid-js/store";
import { createMemo, createSignal, For, Show } from "solid-js";
import Decimal from "decimal.js";
import { service } from "../../utils/utils.request";
import { creToast } from "../../utils/utils.html";
import { getNewObject } from "../../utils/utils.object";
import { AcrRecord, Wallet } from "../../store/global.data.type";
import {
  setLocAllTypeMap,
  setLocRecordList,
  setLocUserInfo,
} from "../../utils/utils.localstroage";
import { allTypeMap, setAllTypeMap } from "../../store/global.filter";
import { arrReduceSum } from "../../utils/utils.array";

export function Property() {
  const [showForm, setShowForm] = createSignal(false);
  const [cfWkIng, setCfWkIng] = createStore({
    wk: false,
  });

  /**
   * 编辑的表单
   */
  const [newWalletList, setNewWalletList] = createStore<Wallet[]>(
    getNewObject(userInfo.walletList)
  );

  /**
   * 持有总和
   */
  const sumMoney = createMemo<Decimal>(
    (): Decimal =>
      arrReduceSum({
        arr: userInfo.walletList,
        ver: "gt",
      })
  );

  /**
   * 负债总和
   */
  const loanMoney = createMemo<Decimal>(
    (): Decimal =>
      arrReduceSum({
        arr: userInfo.walletList,
        ver: "lt",
      })
  );

  async function submit() {
    //console.log("----------", JSON.stringify(newWalletList));
    if (!showForm()) {
      setShowForm(true);
      return;
    }
    if (cfWkIng.wk) return;
    setCfWkIng("wk", true);
    const res = await service({
      url: "/ab/vpz",
      method: "POST",
      data: JSON.stringify(newWalletList),
    });
    const { status, data } = res;

    if (status == 200) {
      // ////console.log("=====", data);
      const result = JSON.parse(data);
      //console.log(status, result);
      // 更新记录存储到本地
      setRecordHistory(
        produce((val) => {
          result.ar.filter(
            (it: AcrRecord) => (it.money = new Decimal(it.money))
          );
          val.unshift(...result.ar);
        })
      );
      for (const element of result.ar) {
        setAllTypeMap(
          parseInt(element.twoId || element.oneId),
          produce((val) => {
            val.money = element.money.add(val.money || 0);
          })
        );
      }
      // 更新用户信息
      setUserInfo(
        produce((val) => {
          for (let index = 0; index < newWalletList.length; index++) {
            const element = newWalletList[index];
            setNewWalletList([index], "money", new Decimal(element.money));
          }
          val.walletList = newWalletList;
        })
      );
      setLocUserInfo(userInfo);
      setLocRecordList(recordHistory);
      setLocAllTypeMap(allTypeMap);

      creToast({ message: "核算成功", type: "success" });
    } else {
      creToast({ message: data, type: "error" });
    }
    setCfWkIng("wk", false);
    setShowForm(false);
  }

  // ------------------------------------------- 钱包添加
  const [addWallet, setAddWallet] = createStore({
    show: false,
    wk: false,
    info: {
      name: "",
      money: "",
    },
  });

  function addWalletSubmit() {
    if (addWallet.show) {
      setAddWallet("show", false);
      if (addWallet.wk) return;
      setAddWallet("wk", true);
      if (!addWallet.info.name || !addWallet.info.money) {
        setAddWallet("wk", false);
        return creToast({ message: "无输入", type: "error" });
      } else {
        service({
          url: "/ab/c-wt",
          method: "POST",
          data: JSON.stringify(addWallet.info),
        }).then((res) => {
          const { status, data } = res;
          // console.log("-----------", res);
          if (status == 201) {
            const obj = JSON.parse(data);
            obj.money = new Decimal(obj.money || 0);
            setUserInfo(
              produce((val) => {
                const arr = val.walletList;
                arr.push(obj);
                val.walletList = arr;
              })
            );
            setNewWalletList(getNewObject(userInfo.walletList));
            setLocUserInfo(userInfo);
            setAddWallet(
              produce((val) => {
                val.wk = false;
                val.info.name = "";
                val.info.money = "";
              })
            );
            return;
          }
          setAddWallet("wk", false);
          creToast({ message: data, type: "error" });
        });
      }
    } else {
      setAddWallet("show", true);
    }
  }

  return (
    <>
      <PageHeader></PageHeader>
      <div class="p_v">
        <div class="opt_v">
          <button
            class="btn bg-primary"
            // onClick={() => setShowForm(!showForm())}
            onClick={submit.bind(null)}
          >
            {cfWkIng.wk ? (
              <span class="loading loading-ring loading-md"></span>
            ) : (
              <span>{`${showForm() ? "确定对账" : "对账"}`}</span>
            )}
          </button>
          <Show when={showForm()}>
            <button class="btn bg-primary" onClick={addWalletSubmit.bind(null)}>
              {addWallet.wk ? (
                <span class="loading loading-ring loading-md"></span>
              ) : (
                <span> {`${addWallet.show ? "确定增加" : "新增"}`}</span>
              )}
            </button>
          </Show>
        </div>

        {showForm() ? (
          <div class="form_v">
            <Show when={addWallet.show}>
              <label class="flex items-center gap-2">
                <input
                  id={`wId-new-n`}
                  type="text"
                  class="input input-bordered name"
                  placeholder="名称"
                  onInput={(event: InputEvent) => {
                    setAddWallet(
                      "info",
                      "name",
                      (event.target as HTMLInputElement).value
                    );
                  }}
                />
                <input
                  id={`wId-new-v`}
                  class="input input-bordered"
                  type="number"
                  placeholder="人民币"
                  onInput={(event: InputEvent) => {
                    setAddWallet(
                      "info",
                      "money",
                      (event.target as HTMLInputElement).value
                    );
                  }}
                />
              </label>
            </Show>
            <For each={newWalletList}>
              {(item: Wallet, inx) => {
                return (
                  <>
                    <label class="flex items-center gap-2">
                      <input
                        id={`wId${item.id}-n`}
                        type="text"
                        class="input input-bordered name"
                        placeholder="名称"
                        value={item.name}
                        onInput={(event: InputEvent) => {
                          setNewWalletList(
                            [inx()],
                            "name",
                            (event.target as HTMLInputElement).value
                          );
                        }}
                      />
                      <input
                        id={`wId${item.id}`}
                        class="input input-bordered"
                        type="number"
                        placeholder="人民币"
                        value={item.money.toString()}
                        onInput={(event: InputEvent) => {
                          setNewWalletList(
                            [inx()],
                            "money",
                            // @ts-ignore
                            (event.target as HTMLInputElement).value
                          );
                        }}
                      />
                    </label>
                  </>
                );
              }}
            </For>

            {/* <button class="btn bg-primary" onClick={submit.bind(null)}>
              {cfWkIng.wk ? (
                <span class="loading loading-ring loading-md"></span>
              ) : (
                <span>确定</span>
              )}
            </button> */}
          </div>
        ) : (
          <div>
            <div>持有：{sumMoney().toString()}</div>
            <div>负债：{loanMoney().toString()}</div>
            ------------------
            <For each={newWalletList}>
              {(item: Wallet) => {
                if (item.money.isZero()) return;
                return (
                  <>
                    <div>
                      {item.name}：{item.money?.toString()}
                    </div>
                  </>
                );
              }}
            </For>
            {/* {userInfo.money?.isNegative() ? (
              <>
               
                <div>负债：{userInfo.bid?.add(userInfo.money).toString()}</div>
              </>
            ) : (
              <>
                <div>持有：{userInfo.money?.toString()}</div>
                <div>负债：{userInfo.bid?.toString()}</div>
              </>
            )} */}
          </div>
        )}
      </div>
    </>
  );
}
