/** @format */

import cloneDeep from "clone-deep";
import Decimal from "decimal.js";
import { CostType } from "../views/home/home.type";
import { userInfo } from "../store/global.data";

/**
 * 获取一个全新深度克隆的对象
 * @param obj
 * @returns
 */
export function getNewObject(obj: any) {
  return cloneDeep(obj);
}

interface ArrReduce {
  obj: CostType;
  key: string;
}
/**
 * 将对象以及子集里的某个属性值进行精确累加 该方法接受set方法时会将计算的属性转换成Decimal对象
 * @param arr
 * @returns
 */
// export function clacCostTypeMoney(opt: ArrReduce): Decimal {
//   const { obj, key } = opt;
//   const res = obj.children.reduce<Decimal>((sum, item: any): Decimal => {
//     const ele = item[key];
//     if (ele) {
//       if (typeof ele == "string") {
//         return sum.add(new Decimal(ele));
//       }
//       return sum.add(ele);
//     }
//     return sum;
//   }, new Decimal(0));
//   return obj.money ? res.add(obj.money) : res;
// }

export function getSubTypeAllMoney(id: number) {
  let res = new Decimal(0);
  for (const key in userInfo.monthTypeMoney) {
    const ele = userInfo.monthTypeMoney[key];
    if (ele.pId == id) {
      res = res.add(ele.money);
    }
  }

  return res;
}
