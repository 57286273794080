/** @format */

import { AcrRecord, UserInfo } from "../store/global.data.type";
import { CostType } from "../views/home/home.type";
import { AllTypeMap } from "../store/global.filter.type";
import Decimal from "decimal.js";
import { updStringToDecimal } from "./utils.array";
/**
 * 清除所有本地存储的缓存
 */
export function clearAllLocalStorage(): void {
  try {
    localStorage.clear();
    ////console.log("所有本地存储的缓存已清除");
  } catch (error) {
    console.error("清除 localStorage 时发生错误:", error);
  }
}
/**
 * 存储
 * @param key
 * @param value
 */
function setLocalStorage(key: string, value: any): void {
  try {
    // 如果 value 是一个对象，先将其转换为 JSON 字符串
    const valueToStore =
      typeof value === "string" ? value : JSON.stringify(value);
    localStorage.setItem(key, valueToStore);
  } catch (error) {
    console.error("设置 localStorage 时发生错误:", error);
  }
}

/**
 * 读取
 * @param key
 * @returns
 */
function getLocalStorage<T>(key: string): T | null {
  try {
    const value = localStorage.getItem(key);
    if (value) {
      // 尝试将字符串解析为 JSON 对象
      return JSON.parse(value) as T;
    }
    return null;
  } catch (error) {
    console.error("读取 localStorage 时发生错误:", error);
    return null;
  }
}

/**
 * 存储本地用户信息
 * @param userInfo
 */
export function setLocUserInfo(userInfo: any) {
  setLocalStorage("userInfo", userInfo);
}

/**
 * 获取本地用户信息
 * @returns
 */
export function getLocUserInfo(): UserInfo | null {
  let uIf = getLocalStorage<UserInfo>("userInfo");
  if (uIf?.walletList) {
    uIf.walletList = updStringToDecimal(uIf.walletList);
  }
  if (uIf?.monthTypeMoney) {
    const adObj: any = {};
    Object.keys(uIf.monthTypeMoney).forEach((key: any) => {
      const item = uIf.monthTypeMoney[key];
      item.money = new Decimal(item.money);
      adObj[item.id] = item;
    });
    uIf.monthTypeMoney = adObj;
  }
  return uIf;
}

/**
 * 存储支出类型
 * @param putType
 */
export function setLocPutType(putType: CostType[]): void {
  setLocalStorage("putType", putType);
}

/**
 * 获取支出类型
 */
export function getLocPutType(): CostType[] | null {
  const array = getLocalStorage<CostType[]>("putType");

  return array;
}

/**
 * 存储收入类型
 * @param outType
 */
export function setLocOutType(outType: CostType[]) {
  setLocalStorage("outType", outType);
}

/**
 * 获取收入类型
 */
export function getLocOutType(): CostType[] | null {
  const array = getLocalStorage<CostType[]>("outType");

  return array;
}

// ------------------------------------------------------------
/**
 * 存储所有分类id-obj的映射
 * @param outType
 */
export function setLocAllTypeMap(data: AllTypeMap) {
  setLocalStorage("allTypeMap", data);
}

/**
 * 获取所有分类id-name的映射
 */
export function getLocAllTypeMap(): AllTypeMap {
  const data = getLocalStorage<AllTypeMap>("allTypeMap");
  ////console.log("//////////////", data);
  let res = {};
  if (data) {
    // @ts-ignore
    // for (const ket in data) {
    //   const item = data[ket];
    //   if (item.money) {
    //     item.money = new Decimal(item.money);
    //   }
    // }
    return Object.assign(res, data);
  }

  return res;
}

// ------------------------------------------------------------
/**
 * 存储收支记录今天的
 * @param outType
 */
export function setLocRecordList(data: AcrRecord[]) {
  setLocalStorage("recordKey", data);
}

/**
 * 获取收支记录今天的
 */
export function getLocRecordList(): AcrRecord[] | null {
  let data = getLocalStorage<AcrRecord[]>("recordKey");
  if (data) {
    data = updStringToDecimal(data);
  }
  return data;
}
// ------------------------------------------------------------
/**
 * 存储是否开启开发者模式
 * @param outType
 */
export function setLocDeveloper(data: Boolean) {
  setLocalStorage("Developer", data ? 1 : 0);
}

/**
 * 获取是否开启开发者模式
 */
export function getLocDeveloper(): 1 | 0 | null {
  let data = getLocalStorage<1 | 0>("Developer");
  return data;
}
