/**
 * @format
 * @refresh reload
 */

import { render, Show } from "solid-js/web";
import { Route, HashRouter } from "@solidjs/router";
import "./index.scss";
import { Home } from "./views/home/home";
import { Record } from "./views/record/record";
import { Login } from "./views/login/login";
import { gld, setGld, userInfo } from "./store/global.data";
import { getQueryParams } from "./utils/utils.string";
import {
  getAllType,
  getRecordList,
  getUserInfo,
  //   updALllPrice,
} from "./logic/global.api";
import { Property } from "./views/property/property";
import "./utils/utils.app";

import { setNavigationBarColor } from "./utils/utils.app";
import { Setting } from "./views/setting/setting";

function preloadUser() {
  setNavigationBarColor("#000000");
}

render(
  () => {
    // const params = useParams();
    // console.log("??? 参数", params);

    const opt = getQueryParams(window.location.href);
    if (opt) {
      // @ts-ignore
      opt.sbh = parseInt(opt.sbh || 0) + 18;
      // 设置运行参数
      setGld(opt);
      // 设置主题
      document.documentElement.dataset.theme = gld.theme;
    }
    console.log("启动数据", gld);

    getAllType();

    ////console.log("本地用户信息", userInfo);
    // 当本地曾经存在用户信息，则尝试获取服务器用户信息
    if (userInfo.id) {
      getUserInfo({
        succeed() {
          //console.log("服务器用户信息", userInfo);
          // updALllPrice();
          getRecordList();
        },
      });
    }

    return (
      // root={Container}
      <>
        <HashRouter>
          <Route path="/" component={Home} />
          <Route path="/record" component={Record} preload={preloadUser} />
          <Route path="/login" component={Login} />
          <Route path="/p" component={Property} preload={preloadUser} />
          <Route path="/setting" component={Setting} preload={preloadUser} />
        </HashRouter>

        {/* 将一些需我通过js注入的组件入进来，让代码可以识别到然后自动引入其相关的依赖代码 */}
        <Show when={false}>
          <dialog class="modal"></dialog>
        </Show>
      </>
    );
  },
  //   () => <App />,
  document.getElementById("root")!
);
