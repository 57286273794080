import { For, Show } from "solid-js";
import { userInfo } from "../../store/global.data";
import { Wallet } from "../../store/global.data.type";
import { createStore } from "solid-js/store";
import { getNewObject } from "../../utils/utils.object";
import Decimal from "decimal.js";
interface WalletListProps {
  /**
   * 选择返回
   * @param value
   * @returns
   */
  selWalletRes?: (value: Wallet) => void;
  /**
   * 比较
   */
  compare?: string;
  /**
   * 标题
   */
  title?: string;
}

export function WalletList({ selWalletRes, compare, title }: WalletListProps) {
  /**
   * 当前选中的钱包
   */
  //   @ts-ignore
  const [selWallet, setSelWallet] = createStore<Wallet>({});
  const defItem = {
    id: 0,
    money: new Decimal(0),
  };
  return (
    <>
      <div>
        <span>{title}</span>
        <div class="m_cont">
          <For each={userInfo.walletList}>
            {(item: Wallet, inx) => {
              return (
                <>
                  {/* @ts-ignore */}
                  <Show when={compare ? item.money[compare](0) : true}>
                    <button
                      class={`btn it${inx() + 1} ${
                        item.id == selWallet.id ? "btn-primary" : ""
                      }`}
                      onClick={() => {
                        let res = defItem;
                        if (selWallet.id == item.id) {
                          setSelWallet(res);
                        } else {
                          res = item;
                          setSelWallet(res);
                        }
                        // @ts-ignore
                        if (selWalletRes) selWalletRes(res);
                      }}
                    >
                      <div class="btn_txt">{item.name}</div>
                      <Show when={item.money}>
                        <p class="bt_m">{item.money.toString()}</p>
                      </Show>
                    </button>
                  </Show>
                </>
              );
            }}
          </For>
        </div>
      </div>
    </>
  );
}
