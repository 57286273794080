// @ts-nocheck
/**
 * 获取url参数
 * @returns
 */
export function getQueryParams(url: string): { [key: string]: string | null } {
  url = url.split("?")[1];
  const urlParams = new URLSearchParams(url);
  const paramsObj: { [key: string]: string | null } = {};
  urlParams.forEach((value, key) => {
    paramsObj[key] = value;
  });
  return paramsObj;
}

/**
 * 中国大陆手机号码正则表达式
 * @param str
 * @returns
 */
export function isPhoneNumber(str: string, area: string = "cn"): boolean {
  const phoneRegex = /^1[3-9]\d{9}$/;

  return phoneRegex.test(str);
}
