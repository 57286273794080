/** @format */

import { createMemo, For, Show } from "solid-js";
import {
  setOutTypeData,
  setSelOneType,
  setTypeData,
  userInfo,
} from "../../../store/global.data";
import { produce } from "solid-js/store";
import Decimal from "decimal.js";
import { CostType } from "../home.type";
import { useNavigate } from "@solidjs/router";
import { getSubTypeAllMoney } from "../../../utils/utils.object";

interface ActTypeProps {
  typArr: CostType[];
  type: 1 | 2;
}

/**
 * 类型明细组件
 * @param param0
 * @returns
 */
export function ActType({ typArr, type }: ActTypeProps) {
  const navigate = useNavigate();

  const handleClick = (it: CostType, inx: number) => {
    setSelOneType({
      val: it,
      inx,
    });
    navigate(`/record`);
  };
  return (
    <>
      <For each={typArr} fallback={<div>Loading...</div>}>
        {(item: CostType, index) => {
          if (type === 1) {
            setTypeData(
              [index()],
              produce((items) => {
                items.sumMoney = createMemo<Decimal>(
                  (): Decimal => getSubTypeAllMoney(item.id)
                );
              })
            );
          } else {
            setOutTypeData(
              [index()],
              produce((items) => {
                items.sumMoney = createMemo<Decimal>(
                  (): Decimal => getSubTypeAllMoney(item.id)
                );
              })
            );
          }

          return (
            <>
              <button
                class={`btn it${index() + 1}`}
                onClick={handleClick.bind(null, item, index())}
              >
                {item.name}
                <Show when={item.sumMoney}>
                  {/* @ts-ignore */}
                  <Show when={!item.sumMoney().isZero()}>
                    {/* @ts-ignore */}
                    <p class="bt_m">{item.sumMoney().toString()}</p>
                  </Show>
                </Show>
                {/* <p class="bt_m">
                  {userInfo.monthTypeMoney[item.id]?.money.toString()}
                </p> */}
              </button>
            </>
          );
        }}
      </For>
    </>
  );
}
