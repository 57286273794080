/** @format */
// @ts-ignore
import uni from "./hybrid_html_uni.webview.1.5.5.js";
import { SetNavigationBarColor } from "./utils.app.type.js";

export function setTheme(theme: string) {
  document.documentElement.dataset.theme = theme;
}
// 接受app端发送的消息
// @ts-ignore
window.setTheme = setTheme;

/**
 * 设置导航栏颜色
 * @param data
 */
export function setNavigationBarColor(data: SetNavigationBarColor) {
  uni.webView.postMessage({
    data: {
      action: "setNavigationBarColor",
      value: data,
    },
  });
}
