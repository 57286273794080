export const router: any = {
  val: {},
};

/**
 * 路由跳转
 */
// export const navTo = useNavigate();

export function appBarck() {
  router.val(-1);
}

export const navTo = (path: string) => {
  router.val(path);
};
