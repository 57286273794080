/** @format */

import "./recordList.scss";
import { For } from "solid-js";
import { allTypeMap } from "../../store/global.filter";
import { recordHistory, setRecordHistory } from "../../store/global.data";
import { produce } from "solid-js/store";
import Decimal from "decimal.js";
import { AcrRecord } from "../../store/global.data.type";
import { formattedDate } from "../../utils/utils.time";

export function RecordList() {
  return (
    <>
      <div class="rl_v">
        <For each={recordHistory}>
          {(item: AcrRecord, inx) => {
            // if (item.money) {
            //   setRecordHistory(
            //     [inx()],
            //     produce((items) => {
            //       items.money = new Decimal(item.money);
            //     })
            //   );
            // }
            return (
              <>
                <div class="rv_i">
                  <span>
                    {allTypeMap[item.twoId]?.before ||
                      allTypeMap[item.oneId]?.name}
                    {allTypeMap[item.twoId]?.name}&nbsp;
                    {item.remark}
                  </span>
                  {/* 如果是负债借入 */}
                  <div class="rv_i_r">
                    {item.type == 1 && item.loan ? (
                      // 如果是负债
                      <span class="loan">-{item.money.toString()}</span>
                    ) : item.type == 1 ? (
                      // 付过是支出
                      <span class="col_put">-{item.money.toString()}</span>
                    ) : item.type == 2 ? (
                      <span class="col_out">+{item.money.toString()}</span>
                    ) : (
                      ""
                    )}
                    <span class="tim">{formattedDate(item.creTime)}</span>
                  </div>
                </div>
              </>
            );
          }}
        </For>
      </div>
    </>
  );
}
