/** @format */

import { createStore } from "solid-js/store";
import { getLocAllTypeMap } from "../utils/utils.localstroage";
import { AllTypeMap } from "./global.filter.type";

/**
 * 所有分类id-Object的映射
 */
const [allTypeMap, setAllTypeMap] = createStore<AllTypeMap>(getLocAllTypeMap());
export { allTypeMap, setAllTypeMap };
// const [allTypeMap, setAllTypeMap] = createStore<AllTypeMap>(
//   getLocAllTypeMap() || {}
// );

// export { allTypeMap, setAllTypeMap };
