import {
  outTypeData,
  setOutTypeData,
  setRecordHistory,
  setTypeData,
  setUserInfo,
  typeData,
} from "../store/global.data";
import { clearAllLocalStorage } from "../utils/utils.localstroage";
import { CostType } from "../views/home/home.type";

/**
 * 根据给定的类型获取数组和设置函数
 * 此函数用于根据type参数的不同返回不同的数组和设置函数
 * 它主要用于初始化或重置特定类型的数组和其对应的设置函数
 *
 * @param type - 一个数字，表示请求的类型如果为1，返回typeData和setTypeData，否则返回outTypeData和setOutTypeData
 * @returns 返回一个数组，其中包含一个CostType数组和一个设置函数
 *          如果没有初始化数据，会返回一个空数组和一个空函数
 */
export function getArrayAndSetFunction(type: number): [CostType[], Function] {
  if (type === 1) {
    return [typeData || [], setTypeData || (() => {})];
  } else {
    return [outTypeData || [], setOutTypeData || (() => {})];
  }
}

/**
 * 退出登录
 */
export function appLogOut() {
  setUserInfo({
    id: 0,
    ps: 0,
    walletList: [],
    account: "",
    name: "",
    img: "",
    monthTypeMoney: {},
  });
  setRecordHistory([]);
  clearAllLocalStorage();
}
