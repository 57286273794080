import { endOfDay, startOfDay } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { createSignal } from "solid-js";

/**
 * 获取当前时区，默认位于中国上海
 */
export const [timeZone, setTimeZone] = createSignal(
  Intl.DateTimeFormat().resolvedOptions().timeZone || "Asia/Shanghai"
);
//console.log("当前时区：", timeZone());

/**
 * 按需格式化时间
 * @param time
 * @param formatStr
 * @returns
 */
export function formattedDate(
  time: Date,
  formatStr: string = "yyyy-MM-dd HH:mm:ss"
): string {
  return formatInTimeZone(time, timeZone(), formatStr);
}

/**
 * 获取今天开始和结束的时间
 *
 * 该函数返回一个包含两个日期的数组，分别表示今天开始和结束的时间
 * 开始时间是今天的0点0分0秒，结束时间是今天的23点59分59秒
 * 这在某些应用场景中非常有用，比如查询或统计今天的数据时需要确定时间范围
 *
 * @returns [Date, Date] 返回一个包含今天开始和结束时间的数组
 */
export function getTodayStartAndEnd(): [Date, Date] {
  const now = new Date();
  return [startOfDay(now), endOfDay(now)];
}
