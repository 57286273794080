/** @format */

import Decimal from "decimal.js";
import { CostType } from "../views/home/home.type";
import { AcrRecord, Wallet } from "../store/global.data.type";
interface TreeNodeMap<CostType> {
  [k: number]: CostType;
}
/**
 * 数组转换为树形结构
 * @param data 对象数组
 * @param id 主键
 * @param pId 父id
 * @param children 子集key名称
 * @returns CostType[]
 */
export function buildTree(
  data: CostType[],
  id: string = "id",
  pId: string = "pId",
  children: string = "children"
): {
  arr: CostType[];
  map: TreeNodeMap<CostType>;
} {
  const map: TreeNodeMap<CostType> = {};
  const result: any[] = [];

  // 首先将所有节点放入 map 中
  data.forEach((item) => {
    // // @ts-ignore
    // if (item.money) {
    //   // @ts-ignore
    //   item.money = new Decimal(item.money);
    // }
    item.children = [];

    // @ts-ignore
    map[item[id]] = item;
  });

  // 然后根据 pId 关联子节点
  data.forEach((item) => {
    // @ts-ignore
    if (item[pId] !== null) {
      // @ts-ignore
      const parent = map[item.pId];
      if (parent) {
        // @ts-ignore
        parent[children].push(map[item.id]!);
      }
    } else {
      // @ts-ignore
      result.push(map[item[id]]!);
    }
  });

  return {
    arr: result,
    map,
  };
}

interface ArrReduce<T> {
  arr: T[];
  ver?: "lt" | "gt";
}
/**
 * 将数组里的某个属性值进行精确累加 该方法接受set方法时会将计算的属性转换成Decimal对象
 * @param arr
 * @returns
 */
export function arrReduce(opt: ArrReduce<CostType>): Decimal {
  const { arr } = opt;
  return arr.reduce<Decimal>((sum, item: CostType): Decimal => {
    if (item.sumMoney) {
      return sum.add(item.sumMoney());
    }
    return sum;
  }, new Decimal(0));
}

/**
 * 计算数组内所有支出总和
 * @param opt
 * @returns
 */
export function arrReduceTwo(opt: ArrReduce<AcrRecord>): Decimal {
  const { arr } = opt;
  return arr.reduce<Decimal>((sum, item: AcrRecord): Decimal => {
    if (item.money) {
      if (item.type == 1) return sum.add(item.money);
    }
    return sum;
  }, new Decimal(0));
}

/**
 * 计算数组内根据money对比0 返回总和
 * @param opt
 * @returns
 */
export function arrReduceSum(opt: ArrReduce<Wallet>): Decimal {
  const { arr } = opt;
  return arr.reduce<Decimal>((sum, item: Wallet): Decimal => {
    if (item.money) {
      if (item.money[opt.ver!](0)) return sum.add(item.money);
    }
    return sum;
  }, new Decimal(0));
}

/**
 *
 * 将对象数组里的某个属性值转换成Decimal对象
 * @param data
 * @param key
 * @returns
 */
export function updStringToDecimal<T>(
  data: Array<T>,
  key: string = "money"
): Array<T> {
  for (let index = 0; index < data.length; index++) {
    const item = data[index];
    // @ts-ignore
    if (item[key]) {
      // @ts-ignore
      item[key] = new Decimal(item[key]);
    }
  }
  return data;
}
